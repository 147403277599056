import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Divider,
  Select,
  Space,
  List
} from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import {
  CUSTOMER_BILLING_ADDRESS,
  CUSTOMER_BILLING_CITY,
  CUSTOMER_BILLING_STATE,
  CUSTOMER_BILLING_ZIP,
  CUSTOMER_COMPANY_NAME,
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_SHIP_TO_BILLING,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  CUSTOMER_TAX_EXEMPT,
  CUSTOMER_TAX_RATE,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import Checkbox from "antd/es/checkbox/Checkbox";
import { configureCustomer } from "../../../../app/utils/models/configure/configureCustomer";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { customerWrites } from "../../../../app/services/firestore/writes/customerWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import { useFirestoreFetch } from "../../../../app/services/hooks/fetch/useFirestoreFetch";
import { taxRateQueries } from "../../../../app/services/firestore/queries/taxRateQueries";

const AddCustomerModal = ({ visible, onCancel, onComplete }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const { fetching, data: taxRates } = useFirestoreFetch(
    taxRateQueries().fetchActiveTaxRates
  );
  const [billingShippingSame, setBillingShippingSame] = useState(false);
  const [saving, setSaving] = useState(false);
  const [defaultDisplayEnabled, setDefaultDisplayEnabled] = useState(false);
  const [emails, setEmails] = useState([]);
  const [input, setInput] = useState('');

  const [form] = Form.useForm();
  const firestore = useFirestore();

  const addEmail = () => {
    setEmails([...emails, input]);
    setInput('');
  }

  const deleteEmail = (email) => {
    setEmails(emails.filter(e => e !== email)); 
  }

  const handleFinish = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const values = form.getFieldsValue();
      const selectedTr = taxRates.find(
        (r) => r.id === values[CUSTOMER_TAX_RATE]
      );
      const taxDetails = selectedTr ? selectedTr : null;
      const taxRate = selectedTr ? selectedTr.rate : null;
      const data = {
        ...values,
        taxDetails: taxDetails,
        customerTaxRate: taxRate,
        additionalContacts: emails,
      };
      const payload = configureCustomer().newCustomer(
        data,
        userData.id,
        orgData
      );
      customerWrites({ firestore }, fsOrgPrefix)
        .createCustomer(payload)
        .then((res) => {
          clearBeforeClose();
          onComplete(res);
          notificationConfirm("Customer created", "");
        })
        .catch((err) => {
          clearBeforeClose();
          console.log(err);

          if (err.message === 'Customer exists') {
            notificationError('Customer exists', 'A customer with the same display name already exists');
          } else {
            notificationError('Something went wrong', 'Customer could not be created');
          }  
        });
    });
  };

  const displayNameRef = React.useRef();

  const clearBeforeClose = () => {
    setDefaultDisplayEnabled(false);
    setSaving(false);
    setBillingShippingSame(false);
    form.resetFields();
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      [CUSTOMER_SHIPPING_ADDRESS]: values[CUSTOMER_BILLING_ADDRESS],
      [CUSTOMER_SHIPPING_CITY]: values[CUSTOMER_BILLING_CITY],
      [CUSTOMER_SHIPPING_STATE]: values[CUSTOMER_BILLING_STATE],
      [CUSTOMER_SHIPPING_ZIP]: values[CUSTOMER_BILLING_ZIP],
    });
  }, [billingShippingSame]);

  const duplicateBilling = (formSuffix, target) => {
    if (!billingShippingSame) return;
    form.setFieldsValue({
      [`customerShipping${formSuffix}`]: target.value,
    });
  };

  const renderOption = (t) => {
    return `${t.name} (${(t.rate * 100).toFixed(2)}%)`;
  };

  const handleValuesChanged = (e, all) => {
    const lastName = all.lastName !== "" ? ` ${all.lastName}` : "";
    const co =
      all[CUSTOMER_COMPANY_NAME] !== "" &&
      `${all.firstName}${all.lastName}` !== ""
        ? ` - ${all[CUSTOMER_COMPANY_NAME]}`
        : all[CUSTOMER_COMPANY_NAME] !== "" &&
          `${all.firstName}${all.lastName}` === ""
        ? `${all[CUSTOMER_COMPANY_NAME]}`
        : "";
    const defaultDisplayName = `${all.firstName}${lastName}${co}`.trim();
    const currDisplay = all[CUSTOMER_DISPLAY_NAME];
    if (currDisplay !== defaultDisplayName && defaultDisplayName !== "") {
      setDefaultDisplayEnabled(true);
    } else {
      setDefaultDisplayEnabled(false);
    }
  };

  const generateDisplayName = () => {
    console.log("generate");

    const values = form.getFieldsValue();
    const firstName = values.firstName;
    const lastName = ` ${values.lastName}`;
    const co =
      values[CUSTOMER_COMPANY_NAME] !== "" &&
      `${firstName}${lastName.trim()}` !== ""
        ? ` - ${values[CUSTOMER_COMPANY_NAME]}`
        : values[CUSTOMER_COMPANY_NAME] !== "" &&
          `${firstName}${lastName.trim()}` === ""
        ? `${values[CUSTOMER_COMPANY_NAME]}`
        : "";
    const defaultDisplayName = `${firstName}${lastName}${co}`.trim();
    form.setFieldsValue({
      [CUSTOMER_DISPLAY_NAME]: defaultDisplayName,
    });
    setDefaultDisplayEnabled(false);
  };

  const handleTaxChange = () => {};


  return (
    <Modal
      destroyOnClose
      width={1000}
      open={visible}
      closeIcon={<></>}
      title="Add Customer"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
          loading={saving}
        >
          Add Customer
        </Button>,
      ]}
    >
      <div>
        <Form form={form} onValuesChange={handleValuesChanged}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"CUSTOMER INFO"} />
          </div>
          <Row gutter={24} wrap={false}>
            <Col span={12}>
              <Row wrap={false} gutter={8}>
                <Col>
                  <Form.Item
                    initialValue=""
                    name="title"
                    style={{ marginBottom: "12px", width: "60px" }}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    initialValue=""
                    name="firstName"
                    style={{ marginBottom: "12px" }}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    initialValue=""
                    name="middleName"
                    style={{ marginBottom: "12px" }}
                  >
                    <Input placeholder="Middle" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    initialValue=""
                    name="lastName"
                    style={{ marginBottom: "12px" }}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    initialValue=""
                    name="suffix"
                    style={{ marginBottom: "12px", width: "60px" }}
                  >
                    <Input placeholder="Suffix" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name={CUSTOMER_COMPANY_NAME}
                initialValue=""
                style={{ marginBottom: "12px" }}
              >
                <Input placeholder="Company name" />
              </Form.Item>
              <Space
                style={{ width: "100%", display: "inline-block" }}
                direction={"vertical"}
                align={"start"}
              >
                <Form.Item
                  name={CUSTOMER_DISPLAY_NAME}
                  style={{ marginBottom: "12px", width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Display name required",
                    },
                    {
                      pattern: /^[^:]*$/, // Regex to disallow ':' character
                      message: "Display name cannot contain ':'",
                    },
                  ]}
                >
                  <Input
                    ref={displayNameRef}
                    placeholder="Display name"
                    className="requiredDisplayName"
                  />
                </Form.Item>
                {defaultDisplayEnabled ? (
                  <Button
                    size={"small"}
                    type="primary"
                    style={{
                      height: "22px",
                      lineHeight: "14px",
                      fontSize: "14px",
                    }}
                    onClick={generateDisplayName}
                  >
                    Generate Display Name
                  </Button>
                ) : null}
              </Space>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue=""
                name="email"
                style={{ marginBottom: "12px" }}
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Row wrap={false} gutter={8}>
                <Col span={12}>
                  <Form.Item
                    initialValue=""
                    name="phone"
                    style={{ marginBottom: "12px" }}
                  >
                    <Input placeholder="Phone" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    initialValue=""
                    name="mobile"
                    style={{ marginBottom: "12px" }}
                  >
                    <Input placeholder="Mobile" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                initialValue=""
                name="otherContact"
                style={{ marginBottom: "12px" }}
              >
                <Input placeholder="Other" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row wrap={false} justify="space-between" gutter={24}>
            <Col>
              <div style={{ marginBottom: "12px" }}>
                <TextSubtitle text={"BILLING ADDRESS"} />
              </div>
              <Form.Item
                initialValue=""
                name={CUSTOMER_BILLING_ADDRESS}
                style={{ marginBottom: "12px" }}
              >
                <Input
                  placeholder="Street"
                  onChange={(e) => duplicateBilling("Address", e.target)}
                />
              </Form.Item>
              <Row wrap={false} justify="space-between">
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={CUSTOMER_BILLING_CITY}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      placeholder="City"
                      onChange={(e) => duplicateBilling("City", e.target)}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={CUSTOMER_BILLING_STATE}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      placeholder="State"
                      onChange={(e) => duplicateBilling("State", e.target)}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    initialValue=""
                    name={CUSTOMER_BILLING_ZIP}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      placeholder="ZIP"
                      onChange={(e) => duplicateBilling("Zip", e.target)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row justify={"space-between"} style={{ marginBottom: "2px" }}>
                <Col>
                  <TextSubtitle text={"SHIPPING ADDRESS"} />
                </Col>
                <Col style={{ textAlign: "top" }}>
                  <Form.Item
                    name={CUSTOMER_SHIP_TO_BILLING}
                    style={{ marginBottom: 0 }}
                    valuePropName={"checked"}
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={(e) => setBillingShippingSame(e.target.checked)}
                    >
                      Use Billing
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                initialValue=""
                name={CUSTOMER_SHIPPING_ADDRESS}
                style={{ marginBottom: "12px" }}
              >
                <Input placeholder="Street" disabled={billingShippingSame} />
              </Form.Item>
              <Row wrap={false} justify="space-between">
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={CUSTOMER_SHIPPING_CITY}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="City" disabled={billingShippingSame} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={CUSTOMER_SHIPPING_STATE}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="State" disabled={billingShippingSame} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    initialValue=""
                    name={CUSTOMER_SHIPPING_ZIP}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="ZIP" disabled={billingShippingSame} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row wrap={false} justify="space-between" gutter={24}>
            <Col span={12}>
              <div style={{ marginBottom: "12px" }}>
                <TextSubtitle text={"TAX INFO"} />
              </div>
              <Form.Item
                valuePropName="checked"
                initialValue={false}
                name={CUSTOMER_TAX_EXEMPT}
                style={{ marginBottom: "12px" }}
              >
                <Checkbox>Tax exempt</Checkbox>
              </Form.Item>
              <Form.Item
                name={CUSTOMER_TAX_RATE}
                style={{ marginBottom: "12px" }}
              >
                <Select
                  disabled={fetching}
                  placeholder={"Select Tax Rate"}
                  onChange={handleTaxChange}
                >
                  {taxRates.map((t) => (
                    <Select.Option key={t.id} value={t.id}>
                      {t && renderOption(t)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
                <div style={{ marginBottom: "12px" }}>
                  <TextSubtitle text={"ADDITIONAL NOTES"} />
                </div>

                <Form.Item
                  name={"notes"}
                  style={{ marginBottom: "12px", width: "100%" }}
                >
                  <Input.TextArea
                    placeholder="Notes"
                    className="requiredDisplayName"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>

                <Form.Item
                  valuePropName="checked"
                  initialValue={false}
                  name={"checkNotesReminder"}
                  style={{ marginBottom: "12px" }}
                >
                  <Checkbox>
                    Remind me to review these notes when added to an order.
                  </Checkbox>
                </Form.Item>
              </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCustomerModal;
