import notificationError from "../../../system-components/toasters/notificationError";
import {
  CUSTOMER_BILLING_ADDRESS,
  CUSTOMER_BILLING_CITY,
  CUSTOMER_BILLING_STATE,
  CUSTOMER_BILLING_ZIP,
  CUSTOMER_COMPANY_NAME,
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_FULL_BILLING_ADDRESS_STR,
  CUSTOMER_FULL_SHIPPING_ADDRESS_STR,
  CUSTOMER_SHIP_TO_BILLING,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  CUSTOMER_TAX_EXEMPT,
  CUSTOMER_TAX_RATE,
  CUSTOMER_TAX_RATE_DETAILS,
} from "../modelConstants/modelConstants";

export const configureCustomer = () => {
  const createBillingAddressStr = (values) => {
    if (
      values[CUSTOMER_BILLING_ADDRESS] === "" ||
      values[CUSTOMER_BILLING_CITY] === "" ||
      values[CUSTOMER_BILLING_STATE] === "" ||
      values[CUSTOMER_BILLING_ZIP] === ""
    )
      return "";
    return `${values[CUSTOMER_BILLING_ADDRESS]}, ${values[CUSTOMER_BILLING_CITY]}, ${values[CUSTOMER_BILLING_STATE]} ${values[CUSTOMER_BILLING_ZIP]}`;
  };
  const createShippingAddressStr = (values) => {
    if (
      values[CUSTOMER_SHIPPING_ADDRESS] === "" ||
      values[CUSTOMER_SHIPPING_CITY] === "" ||
      values[CUSTOMER_SHIPPING_STATE] === "" ||
      values[CUSTOMER_SHIPPING_ZIP] === ""
    )
      return "";
    return `${values[CUSTOMER_SHIPPING_ADDRESS]}, ${values[CUSTOMER_SHIPPING_CITY]}, ${values[CUSTOMER_SHIPPING_STATE]} ${values[CUSTOMER_SHIPPING_ZIP]}`;
  };

  function newCustomerQuickAdd(displayName, createdById, orgData) {
    if (!displayName || !createdById || !orgData)
      return notificationError("Display name not found", "");
    return {
      orgId: orgData.id,
      [CUSTOMER_COMPANY_NAME]: "",
      [CUSTOMER_BILLING_ADDRESS]: "",
      [CUSTOMER_BILLING_CITY]: "",
      [CUSTOMER_BILLING_STATE]: "",
      [CUSTOMER_BILLING_ZIP]: "",
      [CUSTOMER_SHIPPING_ADDRESS]: "",
      [CUSTOMER_SHIPPING_CITY]: "",
      [CUSTOMER_SHIPPING_STATE]: "",
      [CUSTOMER_SHIPPING_ZIP]: "",
      [CUSTOMER_TAX_RATE]: 0,
      [CUSTOMER_DISPLAY_NAME]: displayName.trim(),
      [CUSTOMER_SHIP_TO_BILLING]: false,
      [CUSTOMER_FULL_BILLING_ADDRESS_STR]: "",
      [CUSTOMER_FULL_SHIPPING_ADDRESS_STR]: "",
      [CUSTOMER_TAX_EXEMPT]: false,
      [CUSTOMER_TAX_RATE]: null,
      [CUSTOMER_TAX_RATE_DETAILS]: null,
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      mobile: "",
      notes: "",
      checkNotesReminder: false,
      damageWaiverExempt: false,
      defaultDiscountPercent: null,
      otherContact: "",
      phone: "",
      suffix: "",
      title: "",
      createdBy: createdById,
      createdOn: new Date(),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
      isActive: true,
      balance: 0,
      credit: 0,
      qbId: null,
      syncToken: null,
    };
  }

  function newCustomer(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not create customer", "");
    return {
      orgId: orgData.id,
      [CUSTOMER_COMPANY_NAME]: values[CUSTOMER_COMPANY_NAME]
        ? values[CUSTOMER_COMPANY_NAME]
        : "",
      [CUSTOMER_BILLING_ADDRESS]: values[CUSTOMER_BILLING_ADDRESS]
        ? values[CUSTOMER_BILLING_ADDRESS]
        : "",
      [CUSTOMER_BILLING_CITY]: values[CUSTOMER_BILLING_CITY]
        ? values[CUSTOMER_BILLING_CITY]
        : "",
      [CUSTOMER_BILLING_STATE]: values[CUSTOMER_BILLING_STATE]
        ? values[CUSTOMER_BILLING_STATE]
        : "",
      [CUSTOMER_BILLING_ZIP]: values[CUSTOMER_BILLING_ZIP]
        ? values[CUSTOMER_BILLING_ZIP]
        : "",
      [CUSTOMER_SHIPPING_ADDRESS]: values[CUSTOMER_SHIPPING_ADDRESS]
        ? values[CUSTOMER_SHIPPING_ADDRESS]
        : "",
      [CUSTOMER_SHIPPING_CITY]: values[CUSTOMER_SHIPPING_CITY]
        ? values[CUSTOMER_SHIPPING_CITY]
        : "",
      [CUSTOMER_SHIPPING_STATE]: values[CUSTOMER_SHIPPING_STATE]
        ? values[CUSTOMER_SHIPPING_STATE]
        : "",
      [CUSTOMER_SHIPPING_ZIP]: values[CUSTOMER_SHIPPING_ZIP]
        ? values[CUSTOMER_SHIPPING_ZIP]
        : "",
      [CUSTOMER_TAX_RATE]: values[CUSTOMER_TAX_RATE]
        ? values[CUSTOMER_TAX_RATE]
        : "",
      [CUSTOMER_DISPLAY_NAME]: values[CUSTOMER_DISPLAY_NAME]
        ? values[CUSTOMER_DISPLAY_NAME].trim()
        : "",
      [CUSTOMER_SHIP_TO_BILLING]: values[CUSTOMER_SHIP_TO_BILLING]
        ? values[CUSTOMER_SHIP_TO_BILLING]
        : false,
      email: values.email ? values.email : "",
      additionalContacts: values.additionalContacts ? values.additionalContacts : [],
      firstName: values.firstName ? values.firstName.trim() : "",
      [CUSTOMER_TAX_EXEMPT]: values[CUSTOMER_TAX_EXEMPT]
        ? values[CUSTOMER_TAX_EXEMPT]
        : false,
      lastName: values.lastName ? values.lastName.trim() : "",
      middleName: values.middleName ? values.middleName : "",
      mobile: values.mobile ? values.mobile : "",
      notes: values.notes ? values.notes : "",
      checkNotesReminder: values.checkNotesReminder ? values.checkNotesReminder : false,
      damageWaiverExempt: values.damageWaiverExempt ? values.damageWaiverExempt : false,
      defaultDiscountPercent: values.defaultDiscountPercent ? values.defaultDiscountPercent : null,
      otherContact: values.otherContact ? values.otherContact : "",
      phone: values.phone ? values.phone : "",
      suffix: values.suffix ? values.suffix : "",
      title: values.title ? values.title : "",
      [CUSTOMER_FULL_BILLING_ADDRESS_STR]: createBillingAddressStr(values),
      [CUSTOMER_FULL_SHIPPING_ADDRESS_STR]: createShippingAddressStr(values),
      [CUSTOMER_TAX_RATE]: values[CUSTOMER_TAX_RATE]
        ? values[CUSTOMER_TAX_RATE]
        : null,
      [CUSTOMER_TAX_RATE_DETAILS]: values.taxDetails ? values.taxDetails : null,
      createdBy: createdById,
      createdOn: new Date(),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
      isActive: true,
      balance: 0,
      credit: 0,
      qbId: values?.qbId ?? null,
      syncToken: values?.syncToken ?? null,
    };
  }

  function editCustomer(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not save customer", "");
    return {
      orgId: orgData.id,
      [CUSTOMER_COMPANY_NAME]: values[CUSTOMER_COMPANY_NAME]
        ? values[CUSTOMER_COMPANY_NAME]
        : "",
      [CUSTOMER_BILLING_ADDRESS]: values[CUSTOMER_BILLING_ADDRESS]
        ? values[CUSTOMER_BILLING_ADDRESS]
        : "",
      [CUSTOMER_BILLING_CITY]: values[CUSTOMER_BILLING_CITY]
        ? values[CUSTOMER_BILLING_CITY]
        : "",
      [CUSTOMER_BILLING_STATE]: values[CUSTOMER_BILLING_STATE]
        ? values[CUSTOMER_BILLING_STATE]
        : "",
      [CUSTOMER_BILLING_ZIP]: values[CUSTOMER_BILLING_ZIP]
        ? values[CUSTOMER_BILLING_ZIP]
        : "",
      [CUSTOMER_SHIPPING_ADDRESS]: values[CUSTOMER_SHIPPING_ADDRESS]
        ? values[CUSTOMER_SHIPPING_ADDRESS]
        : "",
      [CUSTOMER_SHIPPING_CITY]: values[CUSTOMER_SHIPPING_CITY]
        ? values[CUSTOMER_SHIPPING_CITY]
        : "",
      [CUSTOMER_SHIPPING_STATE]: values[CUSTOMER_SHIPPING_STATE]
        ? values[CUSTOMER_SHIPPING_STATE]
        : "",
      [CUSTOMER_SHIPPING_ZIP]: values[CUSTOMER_SHIPPING_ZIP]
        ? values[CUSTOMER_SHIPPING_ZIP]
        : "",
      [CUSTOMER_TAX_RATE]: values[CUSTOMER_TAX_RATE]
        ? values[CUSTOMER_TAX_RATE]
        : 0,
      [CUSTOMER_DISPLAY_NAME]: values[CUSTOMER_DISPLAY_NAME]
        ? values[CUSTOMER_DISPLAY_NAME].trim()
        : "",
      email: values.email ? values.email : "",
      additionalContacts: values.additionalContacts ? values.additionalContacts : [],
      firstName: values.firstName ? values.firstName.trim() : "",
      [CUSTOMER_TAX_EXEMPT]: values[CUSTOMER_TAX_EXEMPT]
        ? values[CUSTOMER_TAX_EXEMPT]
        : false,
      [CUSTOMER_SHIP_TO_BILLING]: values[CUSTOMER_SHIP_TO_BILLING]
        ? values[CUSTOMER_SHIP_TO_BILLING]
        : false,
      lastName: values.lastName ? values.lastName.trim() : "",
      middleName: values.middleName ? values.middleName : "",
      mobile: values.mobile ? values.mobile : "",
      notes: values.notes ? values.notes : "",
      
      checkNotesReminder: values.checkNotesReminder ? values.checkNotesReminder : false,
      damageWaiverExempt: values.damageWaiverExempt ? values.damageWaiverExempt : false,
      defaultDiscountPercent: values.defaultDiscountPercent ? values.defaultDiscountPercent : null,

      otherContact: values.otherContact ? values.otherContact : "",
      phone: values.phone ? values.phone : "",
      suffix: values.suffix ? values.suffix : "",
      title: values.title ? values.title : "",
      [CUSTOMER_TAX_RATE]: values[CUSTOMER_TAX_RATE]
        ? values[CUSTOMER_TAX_RATE]
        : null,
      [CUSTOMER_TAX_RATE_DETAILS]: values.taxDetails ? values.taxDetails : null,
      [CUSTOMER_FULL_BILLING_ADDRESS_STR]: createBillingAddressStr(values),
      [CUSTOMER_FULL_SHIPPING_ADDRESS_STR]: createShippingAddressStr(values),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
    };
  }

  return {
    newCustomer: newCustomer, // configure new customer
    newCustomerQuickAdd: newCustomerQuickAdd, // configure new customer just display name
    editCustomer: editCustomer, // configure edited customer
  };
};
