import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import "../../App.less";
import { Affix, Button, Dropdown, Menu, PageHeader, Space } from "antd";
import CreateCategoryModal from "./components/CreateCategoryModal";
import { useFirestore } from "react-redux-firebase";
import { categoryQueries } from "../../app/services/firestore/queries/categoryQueries";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import EditCategoryModal from "./components/EditCategoryModal";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { inventoryQueries } from "../../app/services/firestore/queries/inventoryQueries";
import { sortBy } from "../../app/utils/sorters/sortBy";
import { ROUTE_ADMIN_CREATE_INVENTORY } from "../../app/routes";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import InventoryDataGrid from "./components/InventoryDataGrid";
import { Box } from "@mui/material";

const ItemDrawer = lazy(() => import("./components/ItemDrawer"));

const AdminInventory = ({ history, inventory, loadInventory, loadingInventory, categories }) => {
  const firestore = useFirestore();

  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);

  const [itemDrawer, setItemDrawer] = useState({
    visible: false,
    item: null,
  });

  // Memoized handler for setting itemDrawer state
  const handleSetItemDrawer = useCallback((drawerState) => {
    setItemDrawer(drawerState);
  }, []);

  const handleItemDrawerComplete = useCallback(() => {
    setItemDrawer({ visible: false, item: null });
    loadInventory();
  }, [loadInventory]);

  // Memoize the extra buttons for PageHeader
  const pageHeaderExtra = useMemo(
    () => (
      <Space size={12}>
        <Button type="primary" onClick={() => history.push(ROUTE_ADMIN_CREATE_INVENTORY)}>
          Add Inventory Item
        </Button>
      </Space>
    ),
    [history]
  );

  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Inventory" extra={pageHeaderExtra} />
      </Affix>
      <Box 
       sx={{
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        borderRadius: "8px",
        backgroundColor: "white",

      }}>
        {inventory && (
          <InventoryDataGrid
            setItemDrawer={handleSetItemDrawer}
            onDuplicateComplete={loadInventory}
            onDeleteComplete={loadInventory}
            data={inventory}
            onEditComplete={loadInventory}
            loadingInventory={loadingInventory}
          />
        )}
        
      </Box>
      <Suspense fallback={<div>Loading Item Details...</div>}>
          <ItemDrawer
            visible={itemDrawer.visible}
            handleCloseDrawer={setItemDrawer}
            item={itemDrawer}
            categories={categories}
            onComplete={handleItemDrawerComplete}
            userData={userData}
            orgData={orgData}
            fsOrgPrefix={fsOrgPrefix}
          />
        </Suspense>
    </div>
  );
};

export default AdminInventory;
