import { CheckTaxRates } from './checkTaxRates';
import { formatDateOnRead } from '../../time/formatTimezoneOffset';
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types';
import { OrganizationData } from '../../../interfaces/organizationData';

const UNDEFINED = 'undefined';

export default function checkOrg(orgSnap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
  const org = orgSnap.data();
  if (!org) return;

  const orgId = () => {
    if (typeof orgSnap.id !== UNDEFINED) {
      return orgSnap.id;
    }
    return '';
  };
  const companyName = () => {
    if (typeof org.companyName !== UNDEFINED) {
      return org.companyName;
    }
    return '';
  };
  const companyAddress = () => {
    if (typeof org.companyAddress !== UNDEFINED) {
      return org.companyAddress;
    }
    return '';
  };
  const showCompanyAddress = () => {
    if (typeof org.showCompanyAddress !== UNDEFINED) {
      return org.showCompanyAddress;
    }
    return true;
  };
  const allowInventoryMaintenance = () => {
    if (typeof org.allowInventoryMaintenance !== UNDEFINED) {
      return org.allowInventoryMaintenance;
    }
    return false;
  };
  const addSubjectLineInvoiceNumber = () => {
    if (typeof org.addSubjectLineInvoiceNumber !== UNDEFINED) {
      return org.addSubjectLineInvoiceNumber;
    }
    return false;
  };
  const companyPhone = () => {
    if (typeof org.companyPhone !== UNDEFINED) {
      return org.companyPhone;
    }
    return '';
  };
  const companyWebsite = () => {
    if (typeof org.companyWebsite !== UNDEFINED) {
      return org.companyWebsite;
    }
    return '';
  };
  const webTaxName = () => {
    if (typeof org.webTaxName !== UNDEFINED) {
      return org.webTaxName;
    }
    return '';
  };
  const adminEmail = () => {
    if (typeof org.adminEmail !== UNDEFINED) {
      return org.adminEmail;
    }
    return '';
  };
  const setUseAdminEmailReply = () => {
    if (typeof org.useAdminEmailReply !== UNDEFINED) {
      return org.useAdminEmailReply;
    }
    return false;
  };
  const webTransactionType = () => {
    if (typeof org.webTransactionType !== UNDEFINED) {
      return org.webTransactionType;
    }
    return '';
  };
  const qbInvoiceDate = () => {
    if (typeof org.qbInvoiceDate !== UNDEFINED) {
      return org.qbInvoiceDate;
    }
    return '';
  };
  const companyOpenTime = () => {
    if (typeof org.companyOpenTime !== UNDEFINED) {
      return org.companyOpenTime;
    }
    return null;
  };
  const companyCloseTime = () => {
    if (typeof org.companyCloseTime !== UNDEFINED) {
      return org.companyCloseTime;
    }
    return null;
  };
  const countryCode = () => {
    if (typeof org.countryCode !== UNDEFINED) {
      return org.countryCode;
    }
    return 'US';
  };
  const currencyCode = () => {
    if (typeof org.currencyCode !== UNDEFINED) {
      return org.currencyCode;
    }
    return 'USD';
  };
  const languageCode = () => {
    if (typeof org.languageCode !== UNDEFINED) {
      return org.languageCode;
    }
    return 'en';
  };
  const updatedBy = () => {
    if (typeof org.updatedBy !== UNDEFINED) {
      return org.updatedBy;
    }
    return '';
  };
  const currentInvoiceCount = () => {
    if (typeof org.currentInvoiceCount !== UNDEFINED) {
      return org.currentInvoiceCount;
    }
    return null;
  };
  const currentEstimateCount = () => {
    if (typeof org.currentEstimateCount !== UNDEFINED) {
      return org.currentEstimateCount;
    }
    return 0;
  };
  const currentPOCount = () => {
    if (typeof org.currentPOCount !== UNDEFINED) {
      return org.currentPOCount;
    }
    return 0;
  };
  const taxRate = () => {
    if (typeof org.taxRate !== UNDEFINED) {
      return CheckTaxRates(org.taxRate, true);
    }
    return null;
  };
  const qboRealmId = () => {
    if (typeof org.qboRealmId !== UNDEFINED) {
      return org.qboRealmId;
    }
    return null;
  };
  const integrations = () => {
    if (typeof org.integrations !== UNDEFINED) {
      return org.integrations;
    } else {
      return {
        qbOnline: false,
        qbDesktop: false,
        stripe: false,
        highLevel: false,
      };
    }
  };
  const highLevelUrls = () => {
    if (typeof org.highLevelUrls !== UNDEFINED) {
      return org.highLevelUrls;
    } else {
      return {
        invoiceCreatedUrl: "",
        customerCreatedUrl: "",
        itemPreppedUrl: "",
        updateStatusUrl: "",
      };
    }
  };

  const qbLegacyLineItems = () => {
    if (typeof org.qboLegacyLineItems !== "undefined") {
      return org.qboLegacyLineItems;
    } else {
      return {
        agencyDeliveryId: "",
        agencyPickupId: "",
        customerContactId: "",
        customerDropOffId: "",
        customerPickupId: "",
        deliveryAddressId: "",
        deliveryFeeId: "",
        itemsIn: "",
        itemsOut: "",
        pickupAddressId: "",
      };
    }
  };

  const bundleManagedBy = () => {
    if (typeof org.bundleManagedBy !== UNDEFINED) {
      return org.bundleManagedBy;
    }
    return null;
  };

  const stripeEnabled = () => {
    if (typeof org.stripeEnabled !== UNDEFINED) {
      return org.stripeEnabled;
    }
    return false;
  };

  const trackInventoryWeight = () => {
    if (typeof org.trackInventoryWeight !== UNDEFINED) {
      return org.trackInventoryWeight;
    }
    return false;
  };

  const automaticallyConvertEstimate = () => {
    if (typeof org.automaticallyConvertEstimate !== UNDEFINED) {
      return org.automaticallyConvertEstimate;
    }
    return true;
  };

  const allowPayInFull = () => {
    if (typeof org.allowPayInFull !== UNDEFINED) {
      return org.allowPayInFull;
    }
    return false;
  };

  const showInvoiceItemImg = () => {
    if (typeof org.showInvoiceItemImg !== UNDEFINED) {
      return org.showInvoiceItemImg;
    }
    return true;
  };

  const showItemName = () => {
    if (typeof org.showItemName !== UNDEFINED) {
      return org.showItemName;
    }
    return true;
  };

  const showDuration = () => {
    if (typeof org.showDuration !== UNDEFINED) {
      return org.showDuration;
    }
    return true;
  };

  const showRate = () => {
    if (typeof org.showRate !== UNDEFINED) {
      return org.showRate;
    }
    return true;
  };

  const showDescription = () => {
    if (typeof org.showDescription !== UNDEFINED) {
      return org.showDescription;
    }
    return true;
  };

  const showTotal = () => {
    if (typeof org.showTotal !== UNDEFINED) {
      return org.showTotal;
    }
    return true;
  };

  const defaultDepositPercent = () => {
    if (typeof org.defaultDepositPercent !== UNDEFINED) {
      return org.defaultDepositPercent;
    }
    return 100;
  };
  const defaultDamageWaiverPercent = () => {
    if (typeof org.defaultDamageWaiverPercent !== UNDEFINED) {
      return org.defaultDamageWaiverPercent;
    }
    return 10;
  };
  const estimateExpirationLength = () => {
    if (typeof org.estimateExpirationLength !== UNDEFINED) {
      return org.estimateExpirationLength;
    }
    return 0;
  };
  const deliveryFeeTaxable = () => {
    if (typeof org.deliveryFeeTaxable !== UNDEFINED) {
      return org.deliveryFeeTaxable;
    }
    return true;
  };

  const qbBidirectionalSync = () => {
    if (typeof org.qbBidirectionalSync !== UNDEFINED) {
      return org.qbBidirectionalSync;
    }
    return false;
  };
  const stripeAccount = () => {
    if (typeof org.stripeAccount !== UNDEFINED) {
      return org.stripeAccount;
    }
    return null;
  };
  const stripeDetailsSubmitted = () => {
    if (typeof org.stripeDetailsSubmitted !== UNDEFINED) {
      return org.stripeDetailsSubmitted;
    }
    return false;
  };
  const orgTimezone = () => {
    if (typeof org.orgTimezone !== UNDEFINED) {
      return org.orgTimezone;
    }
    return 'America/Phoenix';
  };
  const timezoneOffsets = () => {
    const tz = orgTimezone();
    const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const logoUrl = () => {
    if (typeof org.logoUrl !== UNDEFINED) {
      return org.logoUrl;
    }
    return '';
  };
  const lat = () => {
    if (typeof org.lat !== UNDEFINED) {
      return org.lat;
    }
    return 0;
  };
  const long = () => {
    if (typeof org.long !== UNDEFINED) {
      return org.long;
    }
    return 0;
  };
  const eventDateEnabled = () => {
    if (typeof org.eventDateEnabled !== UNDEFINED) {
      return org.eventDateEnabled;
    }
    return false;
  };
  const salesInvoiceEnabled = () => {
    if (typeof org.salesInvoiceEnabled !== UNDEFINED) {
      return org.salesInvoiceEnabled;
    }
    return false;
  };
  const embeddedCheckoutEnabled = () => {
    if (typeof org.embeddedCheckoutEnabled !== UNDEFINED) {
      return org.embeddedCheckoutEnabled;
    }
    return false;
  };

  const orgData: OrganizationData = {
    id: orgId(),
    createdOn: org.createdOn ? org.createdOn : new Date(),
    allowInventoryMaintenance: allowInventoryMaintenance(),
    addSubjectLineInvoiceNumber: addSubjectLineInvoiceNumber(),
    allowPayInFull: allowPayInFull(),
    companyName: companyName(),
    companyAddress: companyAddress(),
    showCompanyAddress: showCompanyAddress(),
    companyPhone: companyPhone(),
    companyWebsite: companyWebsite(),
    countryCode: countryCode(),
    languageCode: languageCode(),
    lat: lat(),
    long: long(),
    currencyCode: currencyCode(),
    embeddedCheckoutEnabled: embeddedCheckoutEnabled(),
    webTaxName: webTaxName(),
    adminEmail: adminEmail(),
    useAdminEmailReply: setUseAdminEmailReply(),
    webTransactionType: webTransactionType(),
    qbInvoiceDate: qbInvoiceDate(),
    companyOpenTime: companyOpenTime(),
    companyCloseTime: companyCloseTime(),
    updatedBy: updatedBy(),
    currentInvoiceCount: currentInvoiceCount(),
    currentEstimateCount: currentEstimateCount(),
    currentPOCount: currentPOCount(),
    bundleManagedBy: bundleManagedBy(),
    taxRate: taxRate(),
    qboRealmId: qboRealmId(),
    stripeEnabled: stripeEnabled(),
    automaticallyConvertEstimate: automaticallyConvertEstimate(),
    showInvoiceItemImg: showInvoiceItemImg(),
    showItemName: showItemName(),
    showDuration: showDuration(),
    showRate: showRate(),
    showDescription: showDescription(),
    showTotal: showTotal(),
    sendReceiptEmail: org.sendReceiptEmail ? org.sendReceiptEmail : false,
    deliveryFeeTaxable: deliveryFeeTaxable(),
    defaultDepositPercent: defaultDepositPercent(),
    defaultDamageWaiverPercent: defaultDamageWaiverPercent(),
    estimateExpirationLength: estimateExpirationLength(),
    qbBidirectionalSync: qbBidirectionalSync(),
    integrations: integrations(),
    highLevelUrls: highLevelUrls(),
    stripeAccount: stripeAccount(),
    justifiSubAccount: org.justifiSubAccount ? org.justifiSubAccount : null,
    justifiBizAccount: org.justifiBizAccount ? org.justifiBizAccount : null,
    justifi: org.justifi ? org.justifi : {
        achRate: 0.01,
        achTx: 50,
        ccRate: 0.0315,
        ccTx: 50
    },
    preferredProcessor: org.preferredProcessor ? org.preferredProcessor : 'stripe',
    stripeDetailsSubmitted: stripeDetailsSubmitted(),
    orgTimezone: orgTimezone(),
    logoUrl: logoUrl(),
    eventDateEnabled: eventDateEnabled(),
    salesInvoiceEnabled: salesInvoiceEnabled(),
    trackInventoryWeight: trackInventoryWeight(),
    baseRate: org.baseRate ? org.baseRate : 6500,
    rangeRate: org.rangeRate ? org.rangeRate : 600,
    subscription: org.subscription ? org.subscription : 'basics',
    stripeCustomerId: org.stripeCustomerId ? org.stripeCustomerId : null,
    subscriptionPlanId: org.subscriptionPlanId ? org.subscriptionPlanId : null,
    subscriptionStatus: org.subscriptionStatus ? org.subscriptionStatus : "inactive",
    grandfathered: org.grandfathered ? org.grandfathered : false,
    useLegacyInvoiceView: org.useLegacyInvoiceView ?? true,
    availabilityToOrderDurationMatch: org.availabilityToOrderDurationMatch ?? true,
    // useLegacyInvoiceView: false,
    deferInventoryLoad: org.deferInventoryLoad ? org.deferInventoryLoad : false,
    qboLegacyLineItems:qbLegacyLineItems(),
  };
  return convertOpenCloseTimes(orgData);
}

const convertOpenCloseTimes = (orgData: OrganizationData) => {
  const tz = orgData?.orgTimezone;
  const companyOpenTime = orgData?.companyOpenTime
    ? formatDateOnRead({
        date: orgData?.companyOpenTime,
        orgTimezone: tz,
      })
    : null;
  const companyCloseTime = orgData?.companyCloseTime
    ? formatDateOnRead({
        date: orgData?.companyCloseTime,
        orgTimezone: tz,
      })
    : null;

  return {
    ...orgData,
    companyOpenTime,
    companyCloseTime,
  };
};
