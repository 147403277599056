export const checkCategory = (snap) => {
  const data = snap.data();
  return {
    id: snap.id,
    createdBy: data.createdBy ? data.createdBy : "",
    createdOn: data.createdOn ? data.createdOn : 0,
    description: data.description ? data.description : "",
    name: data.name ? data.name : "",
    orgId: data.orgId ? data.orgId : "",
    updatedBy: data.updatedBy ? data.updatedBy : "",
    updatedOn: data.updatedOn ? data.updatedOn : 0,
    imageUrl: data.imageUrl ? data.imageUrl : "",
    isActive: data.isActive ? data.isActive : false,
    showInShop: data.showInShop ? data.showInShop : false,
    shopName: data.shopName ? data.shopName : (data.name ? data.name : ""),
    shopCategoryProductsSlug: data.shopCategoryProductsSlug ? data.shopCategoryProductsSlug : "",
  };
};
