import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "../../App.less";

import AdminInventory from "./AdminInventory";

import { authSelector } from "../auth/authSlice";
import { Box, Tab, Tabs } from "@mui/material";
import { useFirestore } from "react-redux-firebase";
import { categoryQueries } from "../../app/services/firestore/queries/categoryQueries";
import { inventoryQueries } from "../../app/services/firestore/queries/inventoryQueries";
import { sortBy } from "../../app/utils/sorters/sortBy";

// Lazy load the components
const AdminParts = lazy(() => import("./AdminParts"));
const AdminCategories = lazy(() => import("./AdminCategories"));
const CalendarView = lazy(() => import("./components/calendar/view/calendar-view"));

const InventoryContainer = ({ history }) => {

  const { orgData, fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [manageCategoriesModal, setManageCategoriesModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState([]);

const loadCategories = useCallback(async () => {
  setLoadingCategories(true);
  const cats = await categoryQueries({ firestore }, fsOrgPrefix).fetchCategories();
  setCategories(sortBy(cats, "name"));
  setLoadingCategories(false);
}, [firestore, fsOrgPrefix]);

const loadInventory = useCallback(async () => {
  setLoadingInventory(true);
  const data = await inventoryQueries(
    { firestore },
    fsOrgPrefix
  ).fetchInventory();
  setInventory(sortBy(data, "name"));
  setLoadingInventory(false);
}, [firestore, fsOrgPrefix]);

// const [tabType, setTabType] = useState("inventory");

  const [tabType, setTabType] = useState(() => {
    // Load from local storage or default to "inventory"
    return localStorage.getItem("adminInventoryTab") || "inventory";
  });

  // Tab change handler

  // Save the selected tab to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("adminInventoryTab", tabType);
  }, [tabType]);

  const handleTabChange = (event, newValue) => {
    setTabType(newValue);
  };

  useEffect(() => {
    // load categories
    fsOrgPrefix && loadCategories();
    fsOrgPrefix && loadInventory();
  }, [fsOrgPrefix, loadCategories, loadInventory]);

  return (
    <div>
      <div style={{ paddingTop: "68px" }}>
        <div style={{ width: "100%", marginLeft: "14px" }}>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabType} onChange={handleTabChange}>
              <Tab label="Inventory" value={"inventory"} />
              <Tab label="Categories" value={"categories"} />
              {orgData && orgData.allowInventoryMaintenance && <Tab label="Maintenance Parts" value="parts" />}
              {orgData && orgData.allowInventoryMaintenance && <Tab label="Maintenance Calendar" value="calendar" />}
            </Tabs>
          </Box>
        </div>

        {tabType === "inventory" && <AdminInventory history={history} inventory={inventory} loadingInventory={loadingInventory} loadInventory={loadInventory} categories={categories}/>}

        {tabType === "categories" && (
          <Suspense fallback={<div>Loading Categories...</div>}>
            <AdminCategories history={history} categories={categories} loadingCategories={loadingCategories} loadCategories={loadCategories}/>
          </Suspense>
        )}

        {tabType === "parts" && (
          <Suspense fallback={<div>Loading Parts...</div>}>
            <AdminParts history={history} />
          </Suspense>
        )}

        {tabType === "calendar" && (
          <Suspense fallback={<div>Loading Calendar...</div>}>
            <CalendarView />
          </Suspense>
        )}

        {/* {tabType === "services" && (< AdminServiceItems history={history}/>)} */}
      </div>
    </div>
  );
};

export default InventoryContainer;
